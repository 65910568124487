/* Page Container */
.contentContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Header */
.header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Section Styling */
.section {
  margin-bottom: 40px;
}

/* Image Styling */
.smaller-img {
  width: 300px; 
  height: auto; 
  display: block; 
  margin: 0 auto 15px;
  border-radius: 10px;
}

/* Styled Buttons (Links) */
.styledLink {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background: linear-gradient(135deg, #131212, #191415);
  padding: 12px 24px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 10px auto;
  text-align: center;
}

/* Hover effect */
.styledLink:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  background: linear-gradient(135deg, #5c7ce2, #3294e9);
}

/* Click effect */
.styledLink:active {
  transform: scale(0.95);
}
