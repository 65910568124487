/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(11, 11, 11);
  padding: 1px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Hamburger Button */
.navbar-button {
  font-size: 36px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 10px;
}

/* Hidden Nav Links */
.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  flex-direction: column;
  width: 100%;
  background: rgb(11, 11, 11);
}

/* Show Menu When Open */
.nav-links.open {
  display: flex;
}

/* Nav Items */
.nav-links li {
  padding: 10px;
  text-align: left;
}

.nav-links a {
  color: white;
  font-size: 18px;
  text-decoration: none;
  padding: 10px;
  display: block;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #6e85c2;
}

body {
  background: rgb(222, 237, 239); /* Background color with RGB values */
  color: #333; /* Text color */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  padding-top: 50px; /* Add padding at the top to prevent content overlap with fixed navbar */
}
