
.form-container {
    text-align: center;
}

.form-container form {
    width: 300px; /* Adjust the width of the form as needed */
    margin: auto; /* Centers the form horizontally */
    padding-top: 15%;
    margin-top: 100px;
}

.logo{
    width: 150px;
    height: 150px;
    animation: rotate 5s linear infinite; /* Rotate animation */
}

@keyframes rotate {
    from {
        transform: rotate(0deg); /* Start rotation from 0 degrees */
    }
    to {
        transform: rotate(360deg); /* End rotation at 360 degrees */
    }
}