.centerMusic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}
  
.centerMusic > div {
    margin: 20px;
    text-align: center; /* Center text content */
}
  
.centerMusic-image {
    display: block;
    margin: 0 auto;
    max-width: 100%; /* Adjust the maximum width as needed */
    height: auto; /* Maintain aspect ratio */
}

.musicButton {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background: linear-gradient(135deg, #131212, #191415);
    padding: 12px 24px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin: 10px auto; /* Ensures proper spacing and centers the button horizontally */
    text-align: center;
    display: block;
}

/* Hover effect */
.musicButton:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, #5c7ce2, #3294e9);
}

/* Click effect */
.musicButton:active {
    transform: scale(0.95);
}

.musicframe {
    background-color: #ddc;
    border: solid 5vmin #eee;
    border-bottom-color: #fff;
    border-left-color: #eee;
    border-radius: 2px;
    border-right-color: #eee;
    border-top-color: #ddd;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
    box-sizing: border-box;
    display: inline-block;
    margin: 0;         /* Remove external margin */
    padding: 2vmin;    /* Adjust padding to make the frame fit snugly */
    text-align: center;
    width: auto;       /* Allow the width to fit the content */
    height: auto;      /* Allow the height to fit the content */
}

.musicframe img {
    border: solid 2px;
    border-bottom-color: #ffe;
    border-left-color: #eed;
    border-right-color: #eed;
    border-top-color: #ccb;
    max-width: 100%; /* Ensure image fits within the frame */
    height: auto;    /* Maintain aspect ratio */
    display: block;  /* Ensures no extra space is taken by the image */
    margin: 0 auto;  /* Centers the image within the container */
}


@media only screen and (max-width: 600px) {
    /* Adjust styles for screens with a maximum width of 600px (suitable for mobile) */
    div.description {
        font-size: 14px; /* Adjust font size */
        line-height: 1.5; /* Adjust line height */
        margin: 10px; /* Add margin */
    }
}