.container-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This ensures the container takes up the full height of the viewport */
    padding-top: 40px;
}

.calendar {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row if needed */
}
  
.calendar > div {
    flex: 1; /* Each link and div pair takes up equal space */
    text-align: center; /* Center the content horizontally */
    margin: 10px; /* Add some margin between each pair */
}

.link-music {
    display: inline-block; /* Ensures the link behaves like a block element */
    width: 150px; /* Adjust width of the link as needed */
    height: 150px; /* Adjust height of the link as needed */
    background-image: url('/src/Icons/music.png'); /* Specify the path to your image */
    background-size: cover; /* Cover the entire area of the link with the background image */
    background-position: center; /* Center the background image */
    text-indent: -9999px; /* Hide the text content */
}
.link-sports {
    display: inline-block; /* Ensures the link behaves like a block element */
    width: 150px; /* Adjust width of the link as needed */
    height: 150px; /* Adjust height of the link as needed */
    background-image: url('/src/Icons/sports.png'); /* Specify the path to your image */
    background-size: contain; /* Cover the entire area of the link with the background image */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    text-indent: -9999px; /* Hide the text content */
}
.link-events {
    display: inline-block; /* Ensures the link behaves like a block element */
    width: 150px; /* Adjust width of the link as needed */
    height: 150px; /* Adjust height of the link as needed */
    background-image: url('/src/Icons/events.png'); /* Specify the path to your image */
    background-size: contain; /* Cover the entire area of the link with the background image */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    text-indent: -9999px; /* Hide the text content */
}

.link-games {
    display: inline-block; /* Ensures the link behaves like a block element */
    width: 150px; /* Adjust width of the link as needed */
    height: 150px; /* Adjust height of the link as needed */
    background-image: url('/src/Icons/games.png'); /* Specify the path to your image */
    background-size: contain; /* Cover the entire area of the link with the background image */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    text-indent: -9999px; /* Hide the text content */
}

.link-comics {
    display: inline-block; /* Ensures the link behaves like a block element */
    width: 150px; /* Adjust width of the link as needed */
    height: 150px; /* Adjust height of the link as needed */
    background-image: url('/src/Icons/comics.png'); /* Specify the path to your image */
    background-size: contain; /* Cover the entire area of the link with the background image */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    text-indent: -9999px; /* Hide the text content */
}

.link-logo {
    width: 200px; /* Adjust as needed */
    height: 200px;
    display: block; /* Ensure the image behaves properly */
    margin: 20px auto 0; /* Add top margin (20px in this case), center horizontally */
    background-image: url('/src/Icons/logo.png'); /* Specify the path to your image */
    background-size: contain; /* Cover the entire area of the link with the background image */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    text-indent: -9999px; /* Hide the text content */
}

.indented-div{
    text-align: center;
}

.indented-h1{
    text-align: center;
}

.indented-link {
    display: block; /* Change to block to ensure it takes up full width */
    text-align: center;
    margin-top: 20px; /* Adjust as needed */
} 

