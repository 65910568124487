.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This ensures the container takes up the full height of the viewport */
}

.form-container {
    text-align: center;
}

.form-container form {
    width: 300px; /* Adjust the width of the form as needed */
    margin: auto; /* Centers the form horizontally */
}

.form-container input {
    width: 100%;
    margin-bottom: 10px;
}

.spacedDiv{
    margin-top: 10px;
    margin-bottom: 10px;
}
