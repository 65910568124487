.centerEvents {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
  
.centerEvents > div {
    margin: 20px;
    text-align: center; /* Center text content */
}
  
.centerEvents-image {
    display: block;
    margin: 0 auto;
    max-width: 100%; /* Adjust the maximum width as needed */
    height: auto; /* Maintain aspect ratio */
}

.eventsButton {
    display: block;
    margin-bottom: 10px; /* Adjust this value as needed for desired vertical spacing */
}

.eventsframe {
    background-color: #ddc;
    border: solid 5vmin #eee;
    border-bottom-color: #fff;
    border-left-color: #eee;
    border-radius: 2px;
    border-right-color: #eee;
    border-top-color: #ddd;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
    box-sizing: border-box;
    display: inline-block;
    margin: 0;         /* Remove external margin */
    padding: 2vmin;    /* Adjust padding to make the frame fit snugly */
    text-align: center;
    width: auto;       /* Allow the width to fit the content */
    height: auto;      /* Allow the height to fit the content */
}

.eventsframe img {
    border: solid 2px;
    border-bottom-color: #ffe;
    border-left-color: #eed;
    border-right-color: #eed;
    border-top-color: #ccb;
    max-width: 100%; /* Ensure image fits within the frame */
    height: auto;    /* Maintain aspect ratio */
    display: block;  /* Ensures no extra space is taken by the image */
    margin: 0 auto;  /* Centers the image within the container */
}


@media only screen and (max-width: 600px) {
    /* Adjust styles for screens with a maximum width of 600px (suitable for mobile) */
    div.description {
        font-size: 14px; /* Adjust font size */
        line-height: 1.5; /* Adjust line height */
        margin: 10px; /* Add margin */
    }
}
