.radio-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.radio-page-gif {
    margin-bottom: 20px;
    width: 250px; /* Increased size */
    height: auto;
}

.radio-page-link {
    margin: 10px 0;
    text-decoration: none;
    color: #0077cc;
    font-size: 18px;
}

.radio-page-div {
    padding-bottom: 2%;
}
