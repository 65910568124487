.form-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form2 {
    width: 300px; /* Adjust width as needed */
}

.form2 input,
.form2 textarea,
.form2 button {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.form2 button {
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.form2 button:hover {
    background-color: #0056b3;
}

.logo2 {
    width: 150px;
    height: 150px;
    display: block; /* Ensure the image behaves as a block element */
    margin: 0 auto; /* Center horizontally by setting left and right margins to auto */
}


