/* Existing .button style */
.button {
    display: block;
    margin: 20px auto 0; /* Adjust the top margin to move it down */
}

/* Center content */
.centerDiv {
    text-align: center;
    margin: 10px 0; /* Adjust margin for spacing */
}

/* Back button styles for h2 elements */
.backButton {
    display: inline-flex; /* Ensures it only takes up necessary space */
    justify-content: center; /* Centers text inside */
    align-items: center; /* Aligns items properly */
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    color: white;
    background: linear-gradient(135deg, #131212, #191415);
    padding: 12px 24px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    width: auto;
    max-width: 250px;
    text-align: center;
    margin: 20px auto; /* Centers the button horizontally */
    display: block; /* Ensures margin auto works */
}

/* Hover effect for back button */
.backButton:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, #5c7ce2, #3294e9);
}

/* Click effect for back button */
.backButton:active {
    transform: scale(0.95);
}

/* Cate container and logo styles */
.catecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.catelogo {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    box-shadow: 0 6px 12px rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease-in-out;
}

.catelogo:hover {
    transform: scale(1.05); /* Slight zoom effect */
}

/* Adjust the visibility toggle sections */
.content {
    margin: 10px 0;
    font-size: 1em; /* Ensure text is readable */
    line-height: 1.5; /* Make text easier to read */
    text-align: center; /* Center-align text */
}

/* Remove background box and padding */
.content div {
    margin-bottom: 15px;
}

/* Apply margin to text content to keep it within boundaries */
.content p {
    margin: 0 20px; /* Adds left-right margin for padding */
    text-align: center; /* Center text */
}

/* Add responsiveness for smaller screens */
@media (max-width: 600px) {
    .content p {
        margin: 0 10px; /* Smaller margin on smaller screens */
    }
}
