.projectLinks {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 15px; /* Adds space between links */
}

/* Styling for all links inside .projectLinks */
.projectLinks a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    color: white;
    background: linear-gradient(135deg, #131212, #191415);
    padding: 12px 24px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
    width: auto;
    max-width: 250px;
    text-align: center;
    margin: 10px auto; /* Ensures spacing and centering */
}

/* Hover effect */
.projectLinks a:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    background: linear-gradient(135deg, #5c7ce2, #3294e9);
}

/* Click effect */
.projectLinks a:active {
    transform: scale(0.95);
}