/* Stickers container */
.stickers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    overflow: hidden;
    padding: 10px;
    margin-top: 100px; /* Add margin-top to push the content below the navbar */
}

/* Stickers images */
.stickers-container img {
    max-width: 100px; /* Set the maximum width to the desired size */
    max-height: 100px; /* Set the maximum height to the desired size */
    width: 100%; /* Ensure the image takes up the entire space */
    height: 100%; /* Ensure the image takes up the entire space */
    margin: 15px;
    object-fit: contain; 
    border-radius: 5px;
}




