/* CustomerInfo.css */
.form-container-shop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

input {
    padding: 8px;
    margin-top: 4px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 200px;
}

.shop-button {
    padding: 8px 16px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
