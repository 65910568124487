/* General shop page container styling */
.shop-page-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

/* Grid layout for larger screens */
.shop-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Adjust grid layout for smaller screens (mobile-friendly) */
@media (max-width: 600px) {
    .shop-grid-container {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
        gap: 10px; /* Smaller gap for mobile */
        padding: 10px; /* Padding to control space from screen edges */
    }

    /* Ensure cards have consistent size and stay within bounds */
    .shop-grid-item {
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        max-width: 100%; /* Ensure cards don't exceed container width */
        text-align: center;
        cursor: pointer;
        transition: transform 0.3s ease;
    }

    /* Adjust card image and title for mobile */
    .shop-item-image {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .shop-item-title {
        font-size: 1.2em; /* Adjust font size for mobile */
    }
}

/* Grid items styling */
.shop-grid-item {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.shop-grid-item:hover {
    transform: scale(1.05);
}

/* Image inside grid items */
.shop-item-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Title for items with responsive font size */
.shop-item-title {
    font-size: clamp(1em, 4vw, 1.5em); /* Dynamically adjusts font size between 1em and 1.5em */
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-top: 10px;
    padding: 5px 10px;
    line-height: 1.2; /* Adjusts line height for better readability */
}

/* Frame for selected item image */
.shop-itemframe {
    margin: 20px;
}

/* Center text for description, price, and size */
.shop-description, .shop-price, .shop-size {
    margin: 10px 0;
    text-align: center;
}

.shop-size select {
  display: inline-block;
  margin: 0 auto;
}

/* Back button styling */
.shop-back-button {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically (if needed) */
    margin: 0 auto;        /* Centers the button itself if it has a defined width */
}

/* Larger back button for mobile screens */
@media (max-width: 600px) {
    .shop-back-button {
        padding: 12px 24px;
        font-size: 18px;
    }
}

/* Horror font styling */
.horror-font {
    font-family: 'Chalkduster', fantasy; /* Optional horror font */
    font-size: 56px;
    color: black; /* Black text color */
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: relative;
    display: inline-block;
    text-shadow: 
      2px 2px 0px #222, /* Dark shadow for depth */
      4px 4px 5px rgba(0, 0, 0, 0.8); /* Deeper shadow for a dramatic effect */
    margin-top: 20px; /* Adjust this value as needed */
}


/* Centering the whole message */
.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  
  /* Styling individual letters */
  .loading-message {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    width: 200px;
    height: 200px;
  }
  
  /* Circular motion for each letter */
  .loading-message span {
    position: absolute;
    transform-origin: 100px 100px; /* Center of the circular path */
    animation: rotateAround 3s linear infinite;
  }
  
  .loading-message span:nth-child(1) {
    animation-delay: 0s;
  }
  .loading-message span:nth-child(2) {
    animation-delay: 0.1s;
  }
  .loading-message span:nth-child(3) {
    animation-delay: 0.2s;
  }
  .loading-message span:nth-child(4) {
    animation-delay: 0.3s;
  }
  .loading-message span:nth-child(5) {
    animation-delay: 0.4s;
  }
  .loading-message span:nth-child(6) {
    animation-delay: 0.5s;
  }
  .loading-message span:nth-child(7) {
    animation-delay: 0.6s;
  }
  .loading-message span:nth-child(8) {
    animation-delay: 0.7s;
  }
  .loading-message span:nth-child(9) {
    animation-delay: 0.8s;
  }
  .loading-message span:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  /* Keyframe for circular motion */
  @keyframes rotateAround {
    0% {
      transform: rotate(0deg) translateX(100px) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(100px) rotate(-360deg);
    }
  }

.buy-button {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically (if needed) */
    margin: 0 auto;        /* Centers the button itself if it has a defined width */
    margin-bottom: 20px;    /* Adds space below the button */
}

.not-for-sale{
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically (if needed) */
    margin: 0 auto;        /* Centers the button itself if it has a defined width */
}
  


