.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This ensures the container takes up the full height of the viewport */
}

.form-container-post {
    text-align: center;
}

.form-container-post form {
    width: 80%; /* Adjust the width of the form as needed */
    max-width: 600px; /* Set maximum width for larger screens */
}

.form-container-post input[type="text"],
.form-container-post input[type="url"],
.form-container-post input[type="date"],
.form-container-post textarea {
    width: 100%;
    margin-bottom: 10px;
}

.form-container-post textarea {
    height: 100px; /* Adjust the height of the textarea as needed */
}

.form-container-post button {
    width: 100%;
}
